import Rails from "rails-ujs";

class ProductsNew {
    init() {
        console.log('Initialize products#new')
        interleukin.initTabs()
        this.initSelect2()
        this.initAutoSubmit()
        this.changeImage()
        this.bindLoadTemplate()
    }

    initSelect2() {
        $('.select2').select2();
    }

    initAutoSubmit() {
        $('.auto-submit').on('change', function(e){
            Rails.fire(this.closest('form'), 'submit');
        })
    }

    changeImage(){
        $(".thumb-img").off("click").on(
            "click",
            function (e) {
                var src_name = $(this).attr('src')
                $('.content-img')[0].setAttribute('src', src_name)
              }
        )
    }

    bindLoadTemplate() {
        $('#load-details-tpl').on('click', function(e){
            e.preventDefault()
            let $link = $(e.currentTarget)
            let element = document.querySelector("trix-editor")

            element.editor.setSelectedRange([0, 0])
            element.editor.insertHTML($link.data('template'))
        })
    }


}

export { ProductsNew };
