class CategoriesIndex {
    init() {
        console.log('Initialize categories#index')
        this.initSelect2()
    }

    initSelect2() {
        $('.select2').select2({
            width: '100%'
        });
    }

}

export { CategoriesIndex };