/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

require("@nathanvda/cocoon")
require("trix")
require("@rails/actiontext")
require("select2/dist/css/select2")
require("select2-bootstrap-theme/dist/select2-bootstrap")
require("trix")

require("@rails/actiontext")
require('@uppy/core/dist/style.css')
require('@uppy/dashboard/dist/style.css')

import 'jquery'
window.$ = window.jQuery = require('jquery');

import 'bootstrap'
import '../styles/application'
import Rails from 'rails-ujs';
import Turbolinks from 'turbolinks';
import * as Bootbox from 'bootbox';
import * as moment from 'moment'
import 'trix/dist/trix.css';
import 'trix/dist/trix.js';
import 'controllers'
import * as ActiveStorage from 'activestorage'
import toastr from 'toastr'
import Interleukin from "../custom/interleukin";
import Select2 from "select2"

Rails.start();
window.moment = moment
window.toastr = toastr
window.bootbox = Bootbox
window.Rails = Rails
window.interleukin = new Interleukin()

Turbolinks.start();
ActiveStorage.start()

$(document).on('turbolinks:load', function() {
    interleukin.init()
});
