import {UppyUtils} from "../../utils/uppy_utils";

class AccountIndex {
    init() {
        console.log('Initialize account#index')
        interleukin.initTabs()
        this.initAutoUpload()
        this.initUppy()
    }

    initAutoUpload() {
        $('.auto-file-upload').on('change', function(e){
            this.closest('form').submit()
        })
    }

    initUppy() {
        let uppy = new UppyUtils
        document.querySelectorAll('[data-uppy]').forEach(element => uppy.setupSingleUppy(element))
    }
}

export { AccountIndex };