import printJS from 'print-js'

class OrdersShow {
    init() {
        console.log('Initialize orders#show')
        interleukin.initTabs()

        $('.print-btn').on('click', function(e){
            e.preventDefault()
            ordersShow.printPo()
        })

    }

    initNewInvoice() {
        let ordershow = this
        $('#invoice_payment_terms').on('change', function(){
            ordershow.toggleBankDetailsForm()
        })
    }

    toggleBankDetailsForm() {
        let pterms = $('#invoice_payment_terms').val()
        if(pterms == 'cod') {
            $('.bank-details-form').hide()
            $('.bank-details-cod').show()
        } else {
            $('.bank-details-form').show()
            $('.bank-details-cod').hide()
        }
    }

    bindInvoiceFilter() {
        $('.invoice-filter').on('change', function(e){
            // collect the values of all .invoice-filter fields
            let url = $('#covered-invoices').data('refresh-url')
            let payload = {}

            $('.invoice-filter').each(function(i, el){
                payload[$(el).attr('name')] = $(el).val()
            })

            $.ajax({
                data: payload,
                url: url,
                dataType: 'script'
            })

        })
    }

    bindDrSummary(){
        $('#show-summary').on('click', function(){
            $('#dr-summary').modal('show')
        })
    }

    bindSoaInvoiceCheckbox() {
        let thisOrder = this
        $('.soa-invoice').on('change', function(e){
            thisOrder.recalculateSoaInvoices(e)
        })
    }

    bindPaymentTermsFilter() {
        $('.payment-terms-filter').on('change', function(e){
            let $terms = $(e.currentTarget)
            let text = $terms.find("option[value='" + $terms.val() + "']").text()
            $('.selected-payment-terms').html(text)
        })
    }

    recalculateSoaInvoices(e) {
        let amountSum = 0
        let vatSum = 0

        $('.soa-invoice:checked').each(function(i, el){
            let $invoice = $(el)
            amountSum += parseFloat($invoice.data('amount'));
            vatSum += parseFloat($invoice.data('vat'));
        });

        let formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'PHP',
        });

        $('.total-vat-selected').html(formatter.format(vatSum))
        $('.total-amount-selected').html(formatter.format(amountSum))
    }


    bindPrintInvoiceBtn() {
        $('.print-invoice-btn').on('click', function(e){
            e.preventDefault()
            ordersShow.printInvoice()
        })
    }

    bindPrintDRBtn() {
        $('.print-dr-btn').on('click', function(e){
            e.preventDefault()
            ordersShow.printDeliveryReceipt()
        })
    }

    bindPrintSOABtn() {
        $('.print-soa-btn').on('click', function(e){
            e.preventDefault()
            ordersShow.printAccountStatement()
        })
    }

    printPo() {
        printJS({
            printable: 'po-report',
            type: 'html',
            targetStyles: ['*']
        })
    }

    printInvoice() {
        printJS({
            printable: 'sales-invoice',
            type: 'html',
            targetStyles: ['*']
        })
    }

    printDeliveryReceipt() {
        printJS({
            printable: 'delivery-receipt',
            type: 'html',
            targetStyles: ['*']
        })
    }

    printAccountStatement() {
        printJS({
            printable: 'account-statement',
            type: 'html',
            targetStyles: ['*']
        })
    }

    viewNotes() {
        $(".view-notes").off("click").on(
            "click",
            function (e) {
                e.preventDefault()
                var num = $(this).data('num')
                var note = $(this).data('note')
                bootbox.alert({
                    title: "Notes for '#"+ num + "'",
                    message: note,
                    backdrop: true,
                    className: 'prompt-bootbox',
                    buttons: {
                        ok: {
                            label: 'Close',
                            className: 'btn-secondary',

                        }
                    }
                })
                $(".prompt-bootbox").find("div.modal-content").addClass("bootbox-content"); //remove this after benson's fix for bootbox-modals

            }
        )
    }

    deleteDR(target_id){
        $(".delete-dr").off("click").on(
            "click",
            function (e) {
                e.preventDefault()
                var dr_num = $(this).data('id').split('-').pop()
                var id = $(this).attr('id').split('-').pop()

                bootbox.confirm({
                    message: "Are you sure you want to delete DR '#"+dr_num+"'?",
                    className: 'prompt-bootbox',
                    backdrop: true,
                    swapButtonOrder: true,
                    buttons: {
                        confirm: {
                            label: 'Yes',
                            className: 'btn-primary'
                        },
                        cancel: {
                            label: 'No',
                            className: 'btn-secondary'
                        }
                    },
                    callback: function (result) {
                        if (result){
                            var url = $('#delete-dr-'+ id).data('url')
                            $.ajax({
                                type: "DELETE",
                                url: url,
                                contentType: "application/json",
                                success: function(e){
                                    toastr.success('Delivery Receipt has been deleted!',"Success", { timeOut: 4000 })
                                    interleukin.updateViaRefreshUrl(target_id)
                                }
                            })
                        }
                    }
                })
                $(".prompt-bootbox").find("div.modal-content").addClass("bootbox-content"); //remove this after benson's fix for bootbox-modals
            }
        )
    }
}

export { OrdersShow };
