class ProductQtyControl {
    init() {
        this.bindQuantityInput()
        this.bindMinus()
        this.bindPlus()
    }

    bindMinus() {
        $('.minus').click(function () {
            let $input = $(this).parent().find('input');
            let count = parseInt($input.val()) - 1;
            let min = parseInt($input.data('min'))
            count = count < min ? min : count;
            $input.val(count);
            $input.trigger('change');


            return false;
        });
    }

    bindPlus(){
        $('.plus').click(function () {
            let $input = $(this).parent().find('input');
            let max = parseInt($input.data('max'))
            let count = parseInt($input.val()) + 1
            count = count > max ? (count - 1) :  count;
            $input.val(count);
            $input.trigger('change');

            return false;
        });
    }

    bindQuantityInput() {
        $('.product-qty').on('change', function(e){
            let $input = $(e.target);
            let count = parseInt($input.val());
            let max = parseInt($input.data('max'))
            let min = parseInt($input.data('min'))
            let $price = $('.info-price')

            if(count > max) {
                toastr.error("You can only order up to a maximum of " +max+ " items of this product.")
                $('.hidden-qty').val(max)
                $('.info-quantity').text(max)
                $price.text($price.data('price'))
                $('.info-subtotal').text($price.data('price'))
                $input.val(min)
                return false
            } else if(count < min) {
                toastr.error("The minimum order quantity for this product is " +min+ " items.")
                $('.hidden-qty').val(min)
                $('.info-quantity').text(min)
                $price.text($price.data('price'))
                $('.info-subtotal').text($price.data('price'))
                $input.val(min)
                return false
            } else {
                // update qty & subtotal
                $('.hidden-qty').val(count)
                $('.info-quantity').text(count)
                let subtotal = ($price.data('price') * count).toFixed(2);
                $price.text(subtotal)

                // TODO: Compute with discount and delivery.
                // For now, just copy the computed subtotal
                $('.info-subtotal').text(subtotal)
            }

        })
    }
}

export { ProductQtyControl };