class OtherTerms {
    bindOtherOption() {
        $('.has-other select').on('change', function(){
            let $field = $(this)
            let $cont = $field.parent('.has-other')
            let target = $cont.data('other-field-cont')

            if($field.val() === 'other') {
                $('.' + target).removeClass('hide')
            } else {
                $('.' + target).addClass('hide')
            }
        })
    }
}

export { OtherTerms };
