import {UppyUtils} from "../../utils/uppy_utils";

class SuppliersEdit {
    init() {
        console.log('Initialize suppliers#edit')
        interleukin.initTabs()
        this.initUppy()
    }

    initUppy() {
        let uppy = new UppyUtils
        document.querySelectorAll('[data-uppy]').forEach(element => uppy.setupAutoSingleUppy(element))
    }
}

export { SuppliersEdit };