const Layout = () => {
    // This will auto show sub menu using the slideDown()
    // when top level menu have a class of .show-sub
    // $('.show-sub + .sub-menu').slideDown();



    // This will collapsed sidebar menu on left into a mini icon menu
    // $('#burger-menu').on('click', function() {
    //     var menuText = $('.menu-item-label,.menu-item-arrow');

    //     if ($('body').hasClass('collapsed-menu')) {
    //         $('body').removeClass('collapsed-menu');

    //         // show current sub menu when reverting back from collapsed menu
    //         $('.show-sub + .sub-menu').slideDown();

    //         $('.sl-sideleft').one('transitionend', function(e) {
    //             menuText.removeClass('op-lg-0-force');
    //             menuText.removeClass('d-lg-none');
    //         });

    //     } else {
    //         $('body').addClass('collapsed-menu');

    //         // hide toggled sub menu
    //         $('.show-sub + .sub-menu').slideUp();

    //         menuText.addClass('op-lg-0-force');
    //         $('.sl-sideleft').one('transitionend', function(e) {
    //             menuText.addClass('d-lg-none');
    //         });
    //     }
    //     return false;
    // });



    // This will expand the icon menu when mouse cursor points anywhere
    // inside the sidebar menu on left. This will only trigget to left sidebar
    // when it's in collapsed mode (the icon only menu)
    // $(document).on('mouseover', function(e) {
    //     e.stopPropagation();

    //     if ($('body').hasClass('collapsed-menu') && $('#btnLeftMenu').is(':visible')) {
    //         var targ = $(e.target).closest('.sl-sideleft').length;
    //         if (targ) {
    //             $('body').addClass('expand-menu');

    //             // show current shown sub menu that was hidden from collapsed
    //             $('.show-sub + .sub-menu').slideDown();

    //             var menuText = $('.menu-item-label,.menu-item-arrow');
    //             menuText.removeClass('d-lg-none');
    //             menuText.removeClass('op-lg-0-force');

    //         } else {
    //             $('body').removeClass('expand-menu');

    //             // hide current shown menu
    //             $('.show-sub + .sub-menu').slideUp();

    //             var menuText = $('.menu-item-label,.menu-item-arrow');
    //             menuText.addClass('op-lg-0-force');
    //             menuText.addClass('d-lg-none');
    //         }
    //     }
    // });



    // This will show sub navigation menu on left sidebar
    // // only when that top level menu have a sub menu on it.
    // $('.nav-item').on('click', function() {
    //     var nextElem = $(this).next();
    //     var thisLink = $(this);

    //     if (nextElem.hasClass('sub-menu')) {

    //         if (nextElem.is(':visible')) {
    //             thisLink.removeClass('show-sub');
    //             nextElem.slideUp();
    //         } else {
    //             $('.nav-item').each(function() {
    //                 $(this).removeClass('show-sub');
    //             });

    //             $('.sub-menu').each(function() {
    //                 $(this).slideUp();
    //             });

    //             thisLink.addClass('show-sub');
    //             nextElem.slideDown();
    //         }
    //         return false;
    //     }
    // });



    // This will trigger only when viewed in small devices
    // #btnLeftMenuMobile element is hidden in desktop but
    // visible in mobile. When clicked the left sidebar menu
    // will appear pushing the main content.
    // $('#btnLeftMenuMobile').on('click', function() {
    //     $('body').addClass('show-left');
    //     return false;
    // });



    // This is the right menu icon when it's clicked, the
    // right sidebar will appear that contains the four tab menu
    // $('#btnRightMenu').on('click', function() {
    //     $('body').addClass('show-right');
    //     return false;
    // });



    // This will hide sidebar when it's clicked outside of it
    // $(document).on('click', function(e) {
    //     e.stopPropagation();

    //     // closing left sidebar
    //     if ($('body').hasClass('show-left')) {
    //         var targ = $(e.target).closest('.sl-sideleft').length;
    //         if (!targ) {
    //             $('body').removeClass('show-left');
    //         }
    //     }

    //     // closing right sidebar
    //     if ($('body').hasClass('show-right')) {
    //         var targ = $(e.target).closest('.sl-sideright').length;
    //         if (!targ) {
    //             $('body').removeClass('show-right');
    //         }
    //     }
    // });

    // custom scrollbar style
    // const PerfectScrollbar = require('perfect-scrollbar');


    // const psLeft = new PerfectScrollbar('.sl-sideleft');
    // const psRight = new PerfectScrollbar('.sl-sideright .tab-pane');
    // $('.sl-sideleft, .sl-sideright .tab-pane').perfectScrollbar();

    
    

   
    $('#burger-menu').on('click', function() {
        $('#main-wrapper').toggleClass('collapse-sidebar');
        var sidebar = $(".sidebar");
        if($('#main-wrapper').hasClass('collapse-sidebar')) {
            if($(window).width() <= 992) {
                $(document).on("mouseup",function(e) {
                    if (!sidebar.is(e.target) 
                        && sidebar.has(e.target).length === 0) {
                        $('#main-wrapper').removeClass('collapse-sidebar');
                    } 
                });
            }
        } 
    });

    // Display search on mobile
    $('.search-icon').on("click", function() {
        $('.navbar-search').show();
        var search = $(".navbar-search");
        $(document).on("mouseup",function(e) {
            if (!search.is(e.target) && search.has(e.target).length === 0) {
                $('.navbar-search').hide();
            }
        });
    })

    
    
    

    

    // highlight syntax highlighter
    $('pre code').each(function(i, block) {
        hljs.highlightBlock(block);
    });

    // Initialize tooltip
    $('[data-toggle="tooltip"]').tooltip();

    // Initialize popover
    $('[data-popover-color="default"]').popover();

    // By default, Bootstrap doesn't auto close popover after appearing in the page
    // resulting other popover overlap each other. Doing this will auto dismiss a popover
    // when clicking anywhere outside of it
    $(document).on('click', function(e) {
        $('[data-toggle="popover"],[data-original-title]').each(function() {
            //the 'is' for buttons that trigger popups
            //the 'has' for icons within a button that triggers a popup
            if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
                (($(this).popover('hide').data('bs.popover') || {}).inState || {}).click = false // fix for BS 3.3.6
            }

        });
    });
}

export default Layout;