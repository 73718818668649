class NegotiationsIndex {
    init() {
        console.log('Initialize negotiations#show')
        interleukin.initTabs()
        negotiationsIndex.cancelNegotiation()
        negotiationsIndex.declineOffer()
        negotiationsIndex.closeOffer()
    }

    cancelNegotiation() {
        $(".cancel-negotiation").off("click").on(
            "click",
            function (e) {
                e.preventDefault()
                // e.stopPropagation()
                bootbox.prompt({
                    title: "Cancel Negotiation",
                    inputType: 'textarea',
                    message: 'Notes',
                    className: 'prompt-bootbox',
                    backdrop: true,
                    swapButtonOrder: true,
                    buttons: {
                        cancel: {
                            label: 'Close',
                            className: 'btn-secondary',

                        },
                        confirm: {
                            label: 'Proceed',
                            className: 'btn-primary',
                        }
                    },
                    callback: function(result){
                        if (result != null){
                            negotiationsIndex.proceedCancellation(result)
                        }
                    }
                })
                $(".prompt-bootbox").find("div.modal-content").addClass("bootbox-content"); //remove this after benson's fix for bootbox-modals
            }
        )
    }
    proceedCancellation(notes_input) {
        var url = $('.cancel-negotiation').data('url')
        var json = {
            negotiation_history: {
                notes: notes_input
            }
        }
        $.ajax({
            type: "PATCH",
            url: url,
            data: JSON.stringify(json),
            contentType: "application/json"
        })

    }

    declineOffer() {
        $(".decline-offer").off("click").on(
            "click",
            function (e) {
                e.preventDefault()
                // e.stopPropagation()
                bootbox.prompt({
                    title: "Decline Offer",
                    inputType: 'textarea',
                    message: 'Notes',
                    className: 'prompt-bootbox',
                    backdrop: true,
                    swapButtonOrder: true,
                    buttons: {
                        cancel: {
                            label: 'Close',
                            className: 'btn-secondary',

                        },
                        confirm: {
                            label: 'Proceed',
                            className: 'btn-primary',
                        }
                    },
                    callback: function(result){
                        if (result != null){
                            negotiationsIndex.proceedDeclination(result)
                        }
                    }
                })
                $(".prompt-bootbox").find("div.modal-content").addClass("bootbox-content"); //remove this after benson's fix for bootbox-modals
            }
        )
    }

    proceedDeclination(notes_input) {
        var url = $('.decline-offer').data('url')
        var json = {
            negotiation_history: {
                notes: notes_input
            }
        }
        $.ajax({
            type: "PATCH",
            url: url,
            data: JSON.stringify(json),
            contentType: "application/json"
        })

    }

    closeOffer() {
        $(".close-offer").off("click").on(
            "click",
            function (e) {
                e.preventDefault()
                // e.stopPropagation()
                bootbox.prompt({
                    title: "Close Offer",
                    inputType: 'textarea',
                    message: 'Notes',
                    className: 'prompt-bootbox',
                    backdrop: true,
                    swapButtonOrder: true,
                    buttons: {
                        cancel: {
                            label: 'Close',
                            className: 'btn-secondary',

                        },
                        confirm: {
                            label: 'Proceed',
                            className: 'btn-primary',
                        }
                    },
                    callback: function(result){
                        if (result != null){
                            negotiationsIndex.proceedClosination(result)
                        }
                    }
                })
                $(".prompt-bootbox").find("div.modal-content").addClass("bootbox-content"); //remove this after benson's fix for bootbox-modals
            }
        )
    }

    proceedClosination(notes_input) {
        var url = $('.close-offer').data('url')
        var json = {
            negotiation_history: {
                notes: notes_input
            }
        }
        $.ajax({
            type: "PATCH",
            url: url,
            data: JSON.stringify(json),
            contentType: "application/json"
        })

    }
}

export { NegotiationsIndex };
