class CheckoutAddress {
    init() {
        console.log('Initialize checkout#choose_address')

        $('.shipping-select').on('change', function(e){
            $('#checkout_selected_id').val($(e.target).data('id'))
            Rails.fire(e.target.closest('form'), 'submit')
        })
        this.editNotes()
    }

    editNotes(){
        $(".edit-notes").off("click").on(
            "click",
            function (e) {
                e.preventDefault()
                var id = $(this).attr('id').split('-').pop()
                var address_name = $('.address-span').data('name')
                bootbox.prompt({
                    title: "Edit Notes for '"+ address_name + "'",
                    inputType: 'textarea',
                    value: $(this).data('notes'),
                    className: 'prompt-bootbox',
                    backdrop: true,
                    swapButtonOrder: true,
                    buttons: {
                        cancel: {
                            label: 'Close',
                            className: 'btn-secondary',

                        },
                        confirm: {
                            label: 'Proceed',
                            className: 'btn-primary',
                        }
                    },
                    callback: function(result){
                        if (result != null){
                            checkoutAddress.proceedEditNotes(id, result)
                        }
                    }
                })
                $(".prompt-bootbox").find("div.modal-content").addClass("bootbox-content"); //remove this after benson's fix for bootbox-modals
            }
        )

      }

      proceedEditNotes(address_id, notes) {
        var url = $('#edit-notes-'+ address_id).data('url')
        var json = {
          address: {
              notes: notes
            }
        }
        $.ajax({
            type: "PATCH",
            url: url,
            data: JSON.stringify(json),
            contentType: "application/json",
            success: function(e){
                if (!notes.trim()){
                    $('.notes-span').get(0).innerText = "Notes: Notes not provided"
                    $(".edit-notes").data('notes', '')
                } else {
                    $('.notes-span').get(0).innerText = "Notes: " + notes
                    $(".edit-notes").data('notes', notes)
                    toastr.success('Notes updated!',"Success", { timeOut: 4000 })
                }
            }
        })
    }

}

export { CheckoutAddress };
