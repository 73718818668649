import Rails from "rails-ujs";
import Uppy from "@uppy/core";
import ActiveStorageUpload from "@excid3/uppy-activestorage-upload";
import Dashboard from "@uppy/dashboard";

class UppyUtils {

    setupSingleUppy(element) {
        let config = {
            autoProceed: true,
            allowMultipleUploads: false,
            logger: Uppy.debugLogger
        }
        this.setupUppy(element, config)
    }

    setupAutoSingleUppy(element) {
        let config = {
            autoProceed: true,
            allowMultipleUploads: false,
            logger: Uppy.debugLogger
        }
        this.setupUppy(element, config)
    }

    setupMultipleUppy(element) {
        let config = {
            autoProceed: true,
            allowMultipleUploads: true,
            logger: Uppy.debugLogger
        }
        this.setupUppy(element, config)
    }

    setupUppy(element, config) {
        let trigger = element.querySelector('[data-behavior="uppy-trigger"]')
        let form = element.closest('form')
        let direct_upload_url = document.querySelector("meta[name='direct-upload-url']").getAttribute("content")
        let field_name = element.dataset.uppy

        console.log(trigger)
        trigger.addEventListener("click", (event) => event.preventDefault())

        let uppy = Uppy(config)

        uppy.use(ActiveStorageUpload, {
            directUploadUrl: direct_upload_url
        })

        uppy.use(Dashboard, {
            trigger: trigger,
            closeAfterFinish: true,
        })

        uppy.on('complete', (result) => {
            element.querySelectorAll('[data-pending-upload]').forEach(element => element.parentNode.removeChild(element))

            result.successful.forEach(file => {
                this.appendUploadedFile(element, file, field_name)
                this.setPreview(element, file)
            })

            uppy.reset()
            Rails.fire(element.closest('form'), 'submit');
        })
    }

    appendUploadedFile(element, file, field_name) {
        const hiddenField = document.createElement('input')

        hiddenField.setAttribute('type', 'hidden')
        hiddenField.setAttribute('name', field_name)
        hiddenField.setAttribute('data-pending-upload', true)
        hiddenField.setAttribute('value', file.response.signed_id)

        element.appendChild(hiddenField)
    }

    setPreview(element, file) {
        let preview = element.querySelector('[data-behavior="uppy-preview"]')
        if (preview) {
            let src = (file.preview) ? file.preview : "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcSpj0DBTVsaja01_xWh37bcutvpd7rh7zEd528HD0d_l6A73osY"
            preview.src = src
        }
    }

}

export { UppyUtils };