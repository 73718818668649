import Rails from "rails-ujs";

class CartShow {
    init() {
        console.log('Initialize cart#show')
        this.bindQuantityInput()
        this.bindInclusionControl()
        this.bindMinus()
        this.bindPlus()
    }


    bindMinus() {
        $('.minus').click(function () {
            let $input = $(this).parent().find('input');
            let count = parseInt($input.val()) - 1;
            let min = parseInt($input.data('min'))
            count = count < min ? min : count;
            $input.val(count);
            $input.trigger('change');
        });
    }

    bindPlus(){
        $('.plus').click(function () {
            let $input = $(this).parent().find('input');
            let count = parseInt($input.val()) + 1
            let max = parseInt($input.data('max'))
            count = count > max ? (count - 1) :  count;
            $input.val(count);
            $input.trigger('change');

        });
    }

    bindQuantityInput() {
        $('.product-qty').on('change', function(e){
            let $input = $(e.target);
            let count = parseInt($input.val());
            let max = parseInt($input.data('max'))
            let min = parseInt($input.data('min'))
            let price = $input.data('price')
            let origCount = $input.data('orig-count')

            if(count > max) {
                toastr.error("You can only order up to a maximum of " + max + " items of this product.")
                $('.product-subtotal-' + $input.data('cp-id')).text(price)
                $input.val(origCount)
                return false
            } else if(count < min) {
                toastr.error("The minimum order quantity for this product is " +min+ " items.")
                // $('.product-subtotal-' + $input.data('cp-id')).text(price)
                $input.val(origCount)
                return false
            } else {
                let subtotal = (price * count).toFixed(2);
                $('.product-subtotal-' + $input.data('cp-id')).text(subtotal)
            }



            Rails.fire(e.target.closest('form'), 'submit');
        })
    }

    bindInclusionControl() {
        $('.inclusion-control').on('change', function(e){
            Rails.fire(e.target.closest('form'), 'submit');
        })
    }
}

export { CartShow };