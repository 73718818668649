'use strict';

require("zxcvbn")

import zxcvbn from 'zxcvbn'

class PasswordStrength {
    constructor(selector) {
        this.wrappers = document.querySelectorAll(selector);
        if(this.wrappers.length > 0) {
            this.init(this.wrappers);
        }
    }
    init(wrappers) {
        wrappers.forEach(wrapper => {
            let bar = wrapper.querySelector('.password-meter-bar');
            let input = wrapper.querySelector('.metered-password');

            console.log(bar)
            console.log(input)
            input.addEventListener('keyup', () => {
                let value = input.value;
                bar.classList.remove('level0', 'level1', 'level2', 'level3', 'level4');
                let result = zxcvbn(value);
                let cls = `level${result.score}`;
                bar.classList.add(cls);
            }, false);
        });
    }


}

export { PasswordStrength };