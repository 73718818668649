import Layout from "../custom/layout";
import { BuyersEdit } from "./views/buyers/buyers_edit";
import { UrlUtils } from "./utils/url_utils";
import { SuppliersEdit } from "./views/suppliers/suppliers_edit";
import { AccountIndex } from "./views/account/account_index";
import { ProductsNew } from "./views/products/products_new";
import { CategoriesIndex } from "./views/categories/categories_index";
import { ProductsShow } from "./views/products/products_show";
import { OrdersShow } from "./views/orders/orders_show";
import { NegotiationsIndex } from "./views/negotiations/negotiations_index";
import { InvoicesIndex } from "./views/invoices/invoices_index";
import { AccountStatementsIndex } from "./views/account_statements/account_statements_index";
import { DeliveryReceiptsIndex } from "./views/delivery_receipts/delivery_receipts_index";
// import { DashboardIndex } from "./views/dashboard/dashboad_index";
import { PasswordStrength } from "./utils/password_strength";
import {FileInputField} from "./utils/file_input_field";
import {CartShow} from "./views/cart/cart_show";
import {CheckoutAddress} from "./views/checkout/checkout_address";
import {CheckoutNewOrder} from "./views/checkout/checkout_new_order";
import {UppyUtils} from "./utils/uppy_utils";
import {OtherTerms} from "./utils/other_terms";
import {InvoicesNew} from "./views/invoices/invoices_new";
import {InvoicesStartPayment} from "./views/invoices/invoices_start_payment";

class Interleukin {
    urlUtils = new UrlUtils()
    init() {
        console.log("Interleukin initialized!")
        Layout()
        this.initViews()
        this.initUtils()
        this.bindSitewideBehaviors()
    }
    initUtils() {
        window.otherTerms = new OtherTerms()
    }
    initViews() {
        // Initialize appropriate modules
        if (document.querySelectorAll(".module_buyers.page_edit, .module_buyers.page_profile").length) {
            window.buyersEdit = new BuyersEdit()
            buyersEdit.init()
        }

        if (document.querySelectorAll(".module_suppliers.page_edit, .module_suppliers.page_profile").length) {
            window.suppliersEdit = new SuppliersEdit()
            suppliersEdit.init()
        }

        if (document.querySelectorAll(".module_account.page_index").length) {
            window.accountIndex = new AccountIndex()
            accountIndex.init()
        }

        if (document.querySelectorAll(".module_products.page_new, .module_products.page_edit").length) {
            window.productsNew = new ProductsNew()
            productsNew.init()
        }

        if (document.querySelectorAll(".module_products.page_show").length) {
            window.productsShow = new ProductsShow()
            productsShow.init()
        }

        if (document.querySelectorAll(".module_cart.page_show").length) {
            window.cartShow = new CartShow()
            cartShow.init()
        }

        if (document.querySelectorAll(".module_checkout.page_choose_address").length) {
            window.checkoutAddress = new CheckoutAddress()
            checkoutAddress.init()
        }

        if (document.querySelectorAll(".module_checkout.page_new_order").length) {
            window.checkoutNewOrder = new CheckoutNewOrder()
            checkoutNewOrder.init()
        }

        if (document.querySelectorAll(".module_categories.page_index").length) {
            window.categoriesIndex = new CategoriesIndex()
            categoriesIndex.init()
        }

        if (document.querySelectorAll(".module_orders.page_show").length) {
            window.ordersShow = new OrdersShow()
            ordersShow.init()
        }

        if (document.querySelectorAll(".module_delivery_receipts.page_index").length) {
            window.ordersShow = new OrdersShow()
        }

        if (document.querySelectorAll(".module_negotiations.page_index, .module_negotiations.page_show").length) {
            window.negotiationsIndex = new NegotiationsIndex()
            negotiationsIndex.init()
        }

        if (document.querySelectorAll(".module_invoices.page_index, .module_orders.page_show").length) {
            window.invoicesIndex = new InvoicesIndex()
            window.invoicesNew = new InvoicesNew()
            window.invoicesStartPayment = new InvoicesStartPayment()
            invoicesIndex.init()
            window.ordersShow = new OrdersShow()
        }

        if (document.querySelectorAll(".module_account_statements.page_index, .module_orders.page_show").length) {
            window.accountStatementsIndex = new AccountStatementsIndex()
            accountStatementsIndex.init()
        }

        if (document.querySelectorAll(".module_delivery_receipts.page_index").length) {
            window.deliveryReceiptIndex = new DeliveryReceiptsIndex()
            deliveryReceiptIndex.init()
        }

        // NO CHARTS IN DASHBOARD FOR NOW
        // if (document.querySelectorAll(".module_dashboard.page_index").length) {
        //     window.dashboardIndex = new DashboardIndex()
        //     dashboardIndex.init()
        // }

        // if (document.querySelectorAll(".module_invitations.page_edit, .module_invitations.page_update, .module_passwords.page_edit").length) {
        //     const passwordMeter = new PasswordStrength('.login-wrapper');
        // }
    }

    bindSitewideBehaviors(){
        new FileInputField().init()

        // init time ago
        $('.timeago').each(function(e){
            let $datetime = $(e.target)
            let timeAgo = moment($datetime.text())
            $datetime.text(moment(timeAgo).fromNow())
        })

    }

    // --------------------- Below are global functions for on demand section refresh ------------------------

    updateViaRefreshUrl(target_id, callback, extraParams, jqueryParams) {

        console.log("Refreshing ".concat(target_id))
        const currentParams = this.urlUtils.allParams()

        const ajaxParams = function ajaxParams(additionalParams) {

                if ($.isEmptyObject(additionalParams)) {

                    return currentParams

                }

                if ($.isEmptyObject(currentParams)) {

                    return additionalParams

                }

                return $.extend(
                    currentParams,
                    additionalParams
                )

            },
            handleRefreshUrlError = function handleRefreshUrlError(response, e) {
                switch (response.status) {
                    case 401:
                        toastr.error('Error 401', 'Failed')
                        break

                    case 500:
                        toastr.error('Error 500', 'Failed')
                        break

                    case 503:
                        toastr.error('Error 503', 'Failed')
                        break

                    case 504:
                        toastr.error('Error 504', 'Failed')
                        break
                }

            }

        $("*[data-refresh-url]").each((idx, obj) => {

            if ($(obj).data("target-id") === target_id) {

                const method = $(obj).data("method") || "GET"
                console.log("Found ".concat(
                    target_id,
                    " [method='"
                ).concat(
                    method,
                    "', refresh-url='"
                ).concat(
                    $(obj).data("refresh-url"),
                    "']"
                ))
                const tempScrollTop = $(window).scrollTop(),
                    additionalParams = typeof extraParams !== "undefined" ? extraParams : {},
                    scrollTop = typeof jqueryParams !== "undefined" ? jqueryParams['scrollTop'] : true,
                    defaultOpts = {
                        "type": method,
                        "dataType": "script",
                        "data": ajaxParams(additionalParams),
                        "complete": function complete() {

                            if (scrollTop) {
                                $(window).scrollTop(tempScrollTop)
                            }

                        }
                    }

                if (callback) {

                    defaultOpts.success = callback

                }

                $.ajaxSetup({
                    "error": handleRefreshUrlError
                })
                $.ajax(
                    $(obj).data("refresh-url"),
                    defaultOpts
                )

            }

        })

    }

    restrictSpecialChars() {
        $('input').bind('input', function() {
            var c = this.selectionStart,
                r = /[^a-z0-9 .,]/gi,
                v = $(this).val();
            if(r.test(v)) {
                $(this).val(v.replace(r, ''));
                c--;
            }
            this.setSelectionRange(c, c);
        });

    }

    initTabs() {
        $("a[data-toggle=\"tabajax\"]").off("click").
        on(
            "click",
            function(e) {
                const $this = $(this),
                    tabId = $this.attr("data-tab")
                new Interleukin().refreshTab(
                    $this,
                    tabId
                )
                $this.tab("show")
                e.preventDefault()
                return false

            }
        )

        this.autoTabClick()
    }

    refreshTab(tabLink, tabId) {
        this.updateViaRefreshUrl(tabId)
    }

    autoTabClick() {
        let fragment = this.urlUtils.fragment()

        console.log('FRAGMENT: ', fragment)
        if(fragment) {
            $('.nav-item a[href="#'.concat(fragment, '"]')).click()
        } else {
            $('ul.nav li.nav-item a.active').click()
        }
    }


}

export default Interleukin;
