import {UppyUtils} from "../../utils/uppy_utils";
import Rails from "rails-ujs";

class BuyersEdit {
    init() {
        console.log('Initialize buyers#edit')
        interleukin.initTabs()
        this.initUppy()
        this.initAutoSubmit()
    }

    initAutoSubmit() {
        $('.auto-submit').on('change', function(e){
            Rails.fire(this.closest('form'), 'submit');
        })
    }

    initUppy() {
        let uppy = new UppyUtils
        document.querySelectorAll('[data-uppy]').forEach(element => uppy.setupAutoSingleUppy(element))
    }
}

export { BuyersEdit };