import {ProductQtyControl} from "../../utils/product_qty_control";
import {OtherTerms} from "../../utils/other_terms";

class ProductsShow {
    init() {
        console.log('Initialize products#show')
        interleukin.initTabs()
        new ProductQtyControl().init()
        this.changeImage()
        console.log('Product scroll top...')
        history.scrollRestoration = "manual";
        $(window).on('beforeunload', function(){
            $(window).scrollTop(0);
        });

    }
    changeImage(){
        $(".thumb-img").off("click").on(
            "click",
            function (e) {
                var src_name = $(this).attr('src')
                $('.content-img')[0].setAttribute('src', src_name)
              }
        )
    }
}

export { ProductsShow };
