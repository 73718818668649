class InvoicesStartPayment {
    init() {
        console.log('Initialize invoices#start_payment')
        this.bindBeforePaymentSubmit()
        this.bindOtherDeductionsRecompute()
    }

    bindBeforePaymentSubmit() {
        $('.start-payment-form').on('ajax:before', function(e){
            let $form = $(e.target)
            let code = $form.data('code')
            let inputTotal = $('#deductions_total_'+code)
            let inputNote = $('#deductions_note_'+code)
            inputTotal.val($('#other_deductions_total').val())
            inputNote.val($('#other_deductions_note').val())

        })
    }

    bindOtherDeductionsRecompute() {
        $('#other_deductions_total').on('keyup', function(e){
            let deductions = parseFloat($(e.target).val())
            let base = parseFloat($('pre#total_amount').data('value'))
            if(!deductions) deductions = 0

            let subtotal = base - deductions
            let formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'PHP',
            });

            if(subtotal < 0) {
                toastr.error('Deductions should not exceed the principal amount')
                $('#total_after_deductions').html(formatter.format(base))
            } else {
                $('#total_after_deductions').html(formatter.format(subtotal))
            }

        })
    }


}

export { InvoicesStartPayment };
