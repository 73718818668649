class InvoicesNew {
    init() {
        console.log('Initialize invoices#new')
        this.initSelect2()
    }

    initSelect2() {
        let $invoiceDrs = $('#invoice-drs')

        $invoiceDrs.select2();

        $invoiceDrs.on('change.select2', function (e) {
            let drs = $invoiceDrs.select2('data').map(a => a.id)

            $.ajax({
                type: "POST",
                url: $invoiceDrs.data("recalculate-url"),
                data: { dr: drs },
                success: function(data){
                    $('#invoice_subtotal').val(Number(data.subtotal).toFixed(2))
                    $('#invoice_vatable').val(Number(data.vatable).toFixed(2))
                    $('#invoice_vat').val(Number(data.vat).toFixed(2))
                    $('#invoice_exempt').val(Number(data.vat_exempt).toFixed(2))
                    $('#invoice_total').val(Number(data.total).toFixed(2))
                }
            })
        });
    }



}

export { InvoicesNew };
