import {CartShow} from "../cart/cart_show";
import {CheckoutAddress} from "./checkout_address";

class CheckoutNewOrder {
    init() {
        console.log('Initialize checkout#new_order')
        new CartShow().init()
        new CheckoutAddress().init()

    }

}

export { CheckoutNewOrder };
