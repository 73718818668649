class UrlUtils {
    getParam (sParam) {
        const sPageURL = window.location.search.substring(1),
            sURLVariables = sPageURL.split("&")
        for (let i = 0; i < sURLVariables.length; i++) {

            const sParameterName = sURLVariables[i].split("=")

            if (sParameterName[0] === sParam) {

                return sParameterName[1]

            }
        }
    }

    allParams (url) {
        let vars = {},
            hash,
            link = url != undefined ? url : document.URL,
            q = link.split("?")[1]
        if (q !== undefined) {
            q = q.split("&")
            for (let i = 0; i < q.length; i++) {
                hash = q[i].split("=")
                vars[hash[0]] = hash[1]
            }
        }
        return vars
    }

    fragment () {
        let url = document.location.toString(),
            fragment
        if (url.match("#")) {
            fragment = url.split("#")[1]
        }
        return fragment
    }

    forceReload () {
        $.ajax({
            "url": window.location.href,
            "headers": {
                "Pragma": "no-cache",
                "Expires": -1,
                "Cache-Control": "no-cache"
            }
        }).done(() => {
            window.location.reload(true)
        })
    }
}

export { UrlUtils };
