class InvoicesIndex {
  init() {
      console.log('Initialize invoices#index')
      interleukin.initTabs()
      invoicesIndex.discontinueInvoice()
      invoicesIndex.viewNotes()
      invoicesIndex.declineInvoice()
  }

  discontinueInvoice(){
    $(".discontinue-invoice").off("click").on(
        "click",
        function (e) {
            e.preventDefault()
            // e.stopPropagation()
            var invoice_num = $(this).data('id').split('-').pop()
            var id = $(this).attr('id').split('-').pop()
            bootbox.prompt({
                title: "Discontinue '#"+invoice_num+ "'",
                inputType: 'textarea',
                message: 'Notes',
                className: 'prompt-bootbox',
                // backdrop: false,
                swapButtonOrder: true,
                buttons: {
                    cancel: {
                        label: 'Close',
                        className: 'btn-secondary',

                    },
                    confirm: {
                        label: 'Proceed',
                        className: 'btn-primary',
                    }
                },
                callback: function(result){
                    if (result != null){
                      invoicesIndex.proceedDiscontinue(id, result)
                    }
                }
            })
            $(".prompt-bootbox").find("div.modal-content").addClass("bootbox-content"); //remove this after benson's fix for bootbox-modals
        }
    )

  }

  proceedDiscontinue(invoice_id, note) {
      var url = $('#discontinue-invoice-'+ invoice_id).data('url')
      var json = {
        invoice: {
            note: note
          }
      }
      $.ajax({
          type: "PATCH",
          url: url,
          data: JSON.stringify(json),
          contentType: "application/json"
      })

  }

  viewNotes() {
    $(".view-notes").off("click").on(
        "click",
        function (e) {
            e.preventDefault()
            var num = $(this).data('num')
            var note = $(this).data('note')
            bootbox.alert({
                title: "Notes for '#"+ num + "'",
                message: note,
                backdrop: true,
                className: 'prompt-bootbox',
                buttons: {
                    ok: {
                        label: 'Close',
                        className: 'btn-secondary',

                    }
                }
            })
            $(".prompt-bootbox").find("div.modal-content").addClass("bootbox-content"); //remove this after benson's fix for bootbox-modals

        })
    }

  declineInvoice() {
    $(".decline-invoice").off("click").on(
        "click",
        function (e) {
            e.preventDefault()
            // e.stopPropagation()
            var invoice_num = $(this).data('id')
            bootbox.prompt({
                title: "Are you sure you want to DECLINE '#"+invoice_num+"'?" ,
                inputType: 'textarea',
                message: 'Notes',
                className: 'prompt-bootbox',
                backdrop: true,
                swapButtonOrder: true,
                buttons: {
                    cancel: {
                        label: 'Close',
                        className: 'btn-secondary',

                    },
                    confirm: {
                        label: 'Proceed',
                        className: 'btn-primary',
                    }
                },
                callback: function(result){
                    if (result != null){
                        invoicesIndex.proceedDeclineInvoice(result)
                    }
                }
            })
            $(".prompt-bootbox").find("div.modal-content").addClass("bootbox-content"); //remove this after benson's fix for bootbox-modals
        }
    )
    }

    proceedDeclineInvoice(notes_input) {
        var url = $('.decline-invoice').data('url')
        var json = {
            notes: notes_input
        }
        $.ajax({
            type: "PUT",
            url: url,
            data: JSON.stringify(json),
            contentType: "application/json"
        })

  }
}

export { InvoicesIndex };
