class AccountStatementsIndex {
    init() {
        console.log('Initialize account_statements#index')
        interleukin.initTabs()
        accountStatementsIndex.discontinueAccountStatement()
        accountStatementsIndex.viewNotes()
        accountStatementsIndex.submitToBuyer()
        accountStatementsIndex.remove()
    }

    discontinueAccountStatement() {
        $(".discontinue-accnt-statement").off("click").on(
            "click",
            function (e) {
                e.preventDefault()
                // e.stopPropagation()
                var statement_num = $(this).data('id').split('-').pop()
                var id = $(this).attr('id').split('-').pop()
                bootbox.prompt({
                    title: "Discontinue '#" + statement_num + "'",
                    inputType: 'textarea',
                    message: 'Notes',
                    className: 'prompt-bootbox',
                    backdrop: true,
                    swapButtonOrder: true,
                    buttons: {
                        cancel: {
                            label: 'Close',
                            className: 'btn-secondary',

                        },
                        confirm: {
                            label: 'Proceed',
                            className: 'btn-primary',
                        }
                    },
                    callback: function (result) {
                        if (result != null) {
                            accountStatementsIndex.proceedDiscontinue(id, result)
                        }
                    }
                })
                $(".prompt-bootbox").find("div.modal-content").addClass("bootbox-content"); //remove this after benson's fix for bootbox-modals
            }
        )

    }

    proceedDiscontinue(statement_id, note) {
        var url = $('#discontinue-accnt-statement-' + statement_id).data('url')
        var json = {
            account_statement: {
                note: note
            }
        }
        $.ajax({
            type: "PATCH",
            url: url,
            data: JSON.stringify(json),
            contentType: "application/json"
        })

    }

    submitToBuyer(){
        console.log('Account Statements JS : submitToBuyer')
        $(".submit-to-buyer-accnt-statement").off("click").on(
            "click",
            function (e) {
                e.preventDefault()
                var statement_num = $(this).data('id').split('-').pop()
                var id = $(this).attr('id').split('-').pop()
                bootbox.prompt({
                    title: "Submit to Buyer '#" + statement_num + "'",
                    inputType: 'textarea',
                    message: 'Notes',
                    className: 'prompt-bootbox',
                    backdrop: true,
                    swapButtonOrder: true,
                    buttons: {
                        cancel: {
                            label: 'Close',
                            className: 'btn-secondary',

                        },
                        confirm: {
                            label: 'Proceed',
                            className: 'btn-primary',
                        }
                    },
                    callback: function (result) {
                        if (result != null) {
                            accountStatementsIndex.proceedSubmitToBuyer(id, result)
                        }
                    }
                })
                $(".prompt-bootbox").find("div.modal-content").addClass("bootbox-content");
            }
        )
    }
    proceedSubmitToBuyer(statement_id, note){
        var url = $('#submit-to-buyer-accnt-statement-' + statement_id).data('url')
        var json = {
            account_statement: {
                note: note
            }
        }
        $.ajax({
            type: "PATCH",
            url: url,
            data: JSON.stringify(json),
            contentType: "application/json"
        })
    }

    remove(){
        console.log('Account Statements JS : remove SOA')
        $(".remove-accnt-statement").off("click").on(
            "click",
            function (e) {
                e.preventDefault()
                var statement_num = $(this).data('id').split('-').pop()
                var id = $(this).attr('id').split('-').pop()
                bootbox.prompt({
                    title: "Remove '#" + statement_num + "'",
                    inputType: 'textarea',
                    message: 'Notes',
                    className: 'prompt-bootbox',
                    backdrop: true,
                    swapButtonOrder: true,
                    buttons: {
                        cancel: {
                            label: 'Close',
                            className: 'btn-secondary',

                        },
                        confirm: {
                            label: 'Proceed',
                            className: 'btn-primary',
                        }
                    },
                    callback: function (result) {
                        if (result != null) {
                            accountStatementsIndex.proceedRemove(id, result)
                        }
                    }
                })
                $(".prompt-bootbox").find("div.modal-content").addClass("bootbox-content");
            }
        )
    }
    proceedRemove(statement_id, note){
        var url = $('#remove-accnt-statement-' + statement_id).data('url')
        var json = {
            account_statement: {
                note: note
            }
        }
        $.ajax({
            type: "PATCH",
            url: url,
            data: JSON.stringify(json),
            contentType: "application/json"
        })
    }

    viewNotes() {
        $(".view-notes").off("click").on(
            "click",
            function (e) {
                e.preventDefault()
                var num = $(this).data('num')
                var note = $(this).data('note')
                bootbox.alert({
                    title: "Notes for '#" + num + "'",
                    message: note,
                    backdrop: true,
                    className: 'prompt-bootbox',
                    buttons: {
                        ok: {
                            label: 'Close',
                            className: 'btn-secondary',

                        }
                    }
                })
                $(".prompt-bootbox").find("div.modal-content").addClass("bootbox-content"); //remove this after benson's fix for bootbox-modals
            }
        )
    }

    recalculateSoaInvoices(e) {
        let amountSum = 0
        let vatSum = 0
        let subTotalSum = 0

        $('.soa-invoice:checked').each(function(i, el){
            let $invoice = $(el)
            amountSum += parseFloat($invoice.data('amount'));
            vatSum += parseFloat($invoice.data('vat'));
            subTotalSum += parseFloat($invoice.data('subtotal'));
        });

        let formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'PHP',
        });

        $('.total-subtotal-selected').html(formatter.format(subTotalSum))
        $('.total-vat-selected').html(formatter.format(vatSum))
        $('.total-amount-selected').html(formatter.format(amountSum))
    }

    bindInvoiceFilter() {
        const supplierId = $('#filter-form').data('supplier-id')
        $('.invoice-filter').on('change', function(e){
            // collect the values of all .invoice-filter fields
            let url = $('#covered-invoices').data('refresh-url')
            let payload = {}

            $('.invoice-filter').each(function(i, el){
                payload[$(el).attr('name')] = $(el).val()
            })

            $.ajax({
                data: payload,
                url: url,
                dataType: 'script'
            })

            $.ajax({
                data: payload,
                url: '/suppliers/'+supplierId+'/account_statements/buyer_info/'+ $('.buyers-filter').val(),
                dataType: 'json',
                success: function(buyer) {
                    $('span.buyer-name').html(buyer.name)
                    $('span.buyer-address').html(buyer.address)
                }
            })

        })
    }

    bindSoaInvoiceCheckbox() {
        let thisOrder = this
        $('.soa-invoice').on('change', function(e){
            thisOrder.recalculateSoaInvoices(e)
        })
    }

    bindPaymentTermsFilter() {
        $('.payment-terms-filter').on('change', function(e){
            let $terms = $(e.currentTarget)
            let text = $terms.find("option[value='" + $terms.val() + "']").text()
            $('.selected-payment-terms').html(text)
        })
    }
}

export {AccountStatementsIndex};
